// Animation on Click
const annClickTrigger = document.querySelectorAll('.anim-click-trigger')
annClickTrigger.forEach(function(e, i) {
    annClickTrigger[i].addEventListener('click', function() {
        const annClickTarget = this.querySelector('.anim-click-target')
        annClickTarget.classList.add('bounce-btn')
        setTimeout(function(){
            annClickTarget.classList.remove('bounce-btn')
        }, 600);
    })
});